@import "~@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-pro/scss/regular.scss";
@import "~@fortawesome/fontawesome-pro/scss/solid.scss";
@import "~@fortawesome/fontawesome-pro/scss/light.scss";
@import "~@fortawesome/fontawesome-pro/scss/brands.scss";

$primary: lightgray;

@mixin primary_button_variant() {

  .color-brix & {
    @include button-variant(#ff6602, #ff6602);
  }
  .color-vastgoedbeheer & {
    @include button-variant(#002F8C, #002F8C);
  }
}

@mixin primary_color($important: false, $darken: false) {

  .color-brix & {
    @if ($darken) {
      @if ($important == true) {
        color: darken(#ff6602, 15%) !important;
      } @else {
        color: darken(#ff6602, 15%);
      }
    } @else {
      @if ($important == true) {
        color: #ff6602 !important;
      } @else {
        color: #ff6602;
      }
    }
  }
  .color-vastgoedbeheer & {
    @if ($darken) {
      @if ($important == true) {
        color: darken(#002F8C, 15%) !important;
      } @else {
        color: darken(#002F8C, 15%);
      }
    } @else {
      @if ($important == true) {
        color: #002F8C !important;
      } @else {
        color: #002F8C;
      }
    }
  }
}

@mixin primary_border_color() {

  .color-brix & {
    border-color: #ff6602;
  }
  .color-vastgoedbeheer & {
    border-color: #002F8C;
  }
}

@mixin primary_background_color($darken: false) {
  @if $darken {
    .color-brix & {
      background-color: darken(#ff6602, 15%);
    }
    .color-vastgoedbeheer & {
      background-color: darken(#002F8C, 15%);
    }
  } @else {
    .color-brix & {
      background-color: #ff6602;
    }
    .color-vastgoedbeheer & {
      background-color: #002F8C;
    }
  }
}

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/_variables.scss";

a {
  @include primary_color();

  @include hover() {
    @include primary_color(false, true);
  }
}

@font-face {
  font-family: 'Ginto Nord';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/GintoNord-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Ginto Nord';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/GintoNord-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Ginto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/GintoNormal-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Ginto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/GintoNormal-Bold.woff') format('woff');
}

.btn-primary {
  @include primary_button_variant();
}

.btn {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;

  border-radius: 0.7em;
}

body {
  font-family: 'Ginto', Arial, serif;
  font-size: 20px;

  line-height: 1.4em;

  min-width: 280px;
  //text-align: center;

  width: 100vw;
  overflow-x: hidden;
}

.nav-logo, .footer-logo {
  position: relative;
  z-index: 1;

  img {
    width: 12em;
    max-width: 100%;
  }
}


#main #less-images {
  display: none;
}


.show-more {
  #main {
    #more-images {
      display: none;
    }

    #less-images {
      display: block;
    }

    .room-image.more {
      display: block;
    }
  }
}

#nav-menu {
  font-size: 20px;

  line-height: 38px;

  color: #313130!important;

  text-decoration: none!important;

  z-index: 10;

  &:hover, &:active {
    border: none;
  }
}
#nav-locale {

  position: relative;


  i {
    vertical-align: middle;
  }


  &:hover, &:active {
    border: none;
  }
}

.nav {
  max-width: 1950px;
  margin-left: auto;
  margin-right: auto;

  font-size: 20px;

  .btn {
    font-size: 20px;
  }
}

#nav-locale {
  i {
    display: none;
  }
  img {
    width: 32px;
    height: 32px;
    vertical-align: center;
  }
}

#header-image-container {
  display: inline-block;

  position: relative;

  width: 1px;
  height: 1px;

  vertical-align: top;

  overflow: visible;

  @include media-breakpoint-down('md', $grid-breakpoints) {
    position: initial;
  }
}

.nav.header-image {
  @include media-breakpoint-up('xl', $grid-breakpoints) {
    margin-bottom: 240px;
  }
  @include media-breakpoint-up('lg', $grid-breakpoints) {
    margin-bottom: 180px;

    a {
      color: white !important;
    }

    .dropdown-menu a {
      color: black !important;
    }
  }

  @include media-breakpoint-down('md', $grid-breakpoints) {
    margin-bottom: 65vw;
  }
  @include media-breakpoint-down('sm', $grid-breakpoints) {
    margin-bottom: 55vw;
  }

}

.nav.header-full {
  a {
    color: white !important;
  }
  a:not(.btn) {
    color: white !important;

    &:not(#nav-menu) {
      text-shadow: 0 2px 4px #000000;text-shadow: 0px 2px 4px #000000;
    }
  }

  .dropdown-menu a {
    color: black !important;
  }


  margin-bottom: 940px;

  @include media-breakpoint-down('xs', $grid-breakpoints) {
    margin-bottom: 600px;
  }

  @include media-breakpoint-down('md', $grid-breakpoints) {
    a:not(.btn) {
      color: black !important;
    }
  }
}

#header-title {

  &:before {
    position: absolute;
    content: "";

    top: 85px;

    width: 80%;
    padding-top: 80%;
    @include primary_background_color();
    z-index: -1;

    clip-path: url(#header-home-clip);
    object-fit: contain;
  }

  width: 1100px;
  min-height: 900px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  position: absolute;
  left: calc(50% + 100px);
  transform: translateX(-50%);

  text-align: left;

  div {
    padding: 0 330px 0 170px;

    h1 {
      margin: 220px 0 20px 0;

      color: white;
      font-size: 56px;
      line-height: 66.4px;

      font-family: "Ginto Nord", serif;
      font-weight: 500;
    }

    p {
      margin: 190px 0 40px 0;
      color: white;
      font-size: 18px;
      line-height: 34.4px;

      font-family: "Ginto Nord", serif;
      font-weight: 300;
    }

    h1 + p {
      margin-top: 20px;
    }

    .btn-primary {
      @include button-variant(black, black);

      color: white;

      margin-right: 20px;
      margin-bottom: 10px;
    }
    .btn-primary:first-of-type {
      @include button-variant(white, white);
      @include primary_color(true);
    }

    .btn-primary:last-of-type {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down('md', $grid-breakpoints) {
    &:before {
      top: 85px;
      width: 700px;
      padding-top: 700px;
      left: 50%;
      transform: translateX(-50%);
    }

    left: 50%;
    width: 500px;
    max-width: 90vw;
    min-height: 500px;

    div {
      padding: 0 10% 0 5%;
      max-width: 100%;

      h1 {
        margin: 160px 0 20px 0;

        font-size: 32px;
        line-height: 42.8px;
      }

      p {
        margin: 130px 0 40px 0;
        font-size: 16px;
        line-height: 27.4px;
      }

      h1 + p {
        margin-top: 20px;
      }

      .btn-primary {
        margin-right: 20px;
      }
      .btn-primary:last-of-type {
        margin-right: 0;
      }
    }
  }

  @include media-breakpoint-down('xs', $grid-breakpoints) {
    &:before {
      top: 40px;
      width: 500px;
      padding-top: 500px;
      left: 50%;
      transform: translateX(-50%);
    }

    div {
      h1 {
        margin: 115px 0 20px 0;

        font-size: 20px;
        line-height: 30px;
      }

      p {
        font-size: 12px;
        line-height: 16px;
      }

      h1 + p {
        margin-top: 20px;
      }
    }

    .btn {
      font-size: 0.6em;
    }
  }
}


#header-image {
  background: #F3F3F3;
  background-size: cover;
  content: "";
  position: absolute;
  top: -1700px;
  left: -200px;
  width: 2489px;
  height: 2726px;
  z-index: -1;
  clip-path: url(#header-clip);
  object-fit: cover;

  @include media-breakpoint-down('md', $grid-breakpoints) {
    width: 80vw;
    height: 87.6vw;
    top: -5vw;
    left: 60vw;
    transform: translateX(-50%);
  }
}

.header-full {
  #header-image-container {
    position: initial;
  }
  #header-image {
    left: calc(50% + 100px);
    top: -706px;
    transform: translateX(-50%);
    width: 1647px;
    height: 1800px;

    @include media-breakpoint-down('md', $grid-breakpoints) {
      top: 0;
      left: 50%;
      width: 823px;
      height: 900px;
    }

    @include media-breakpoint-down('xs', $grid-breakpoints) {
      top: 0;
      left: 50%;
      width: 600px;
      height: 650px;
    }
  }


}

.nav > div > a:not(.nav-logo), .nav > div > .dropdown {
  margin: 0 13px;

  display: inline;

  position: relative;

  &:last-child {
    margin-right: 0px;
  }
}

.nav .dropdown-menu {
  border-radius: 24px;
  border: none;
  overflow: hidden;
  box-shadow: 0 5px 14px 0 rgba(0,0,0,0.26);

  .dropdown-item {
    @include primary_color();

    &:active {
      color: white;
    }
  }
}

.nav a:not(.nav-logo):not(.btn):not(.dropdown-item) {
  color: black;
  line-height: 2.5em;
}

.btn-circle {
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 24px;
  text-align: center;
  line-height: 42px;

  display: inline-block;

  cursor: pointer;

  @include primary_button_variant();

  i {
    vertical-align: middle;

    font-size: 0.6em;
  }
}

#main {
  margin-bottom: 120px;

  h1 {
    font-size: 2.5em;
  }

  p, ul {
    font-size: 1.2em;
    line-height: 1.6;
    color: #606060;
  }

  .btn {
    font-size: 1em;

    margin: 1em 0.5em;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  .label {
    font-size: 0.8em;

    display: inline-block;
    font-weight: 400;

    color: #fff;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    @include primary_background_color();
    border: 1px solid #000;
    @include primary_border_color();
    line-height: 1.5;

    padding: 0.2rem 0.8em 0.3em;
    border-radius: 0.7em;
  }

  .showcase-buttons {
    display: flex;
    flex-direction: row;

    div {
      &:first-child {
        margin-left: auto;
      }
      margin-top: auto;
      margin-left: 10px;
    }
  }

  @include media-breakpoint-down('md', $grid-breakpoints) {
    h1 {
      font-size: 1em;
    }
    p, ul {
      font-size: 0.8em;
    }
    .btn {
      font-size: 0.6em;
    }
    .label {
      font-size: 0.8em;
    }
  }
}

.form_element {
  label {
    display: block;
    color: #000000;

    font-family: "Ginto Nord", serif;

    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }

  input {
    border: 1px solid #e1e1e1;
  }

  input[type=submit] {

  }
}

#main ul.list {
  margin: 20px 0;

  padding-left: 30px;

  list-style:none;

  li {
    margin-bottom: 32px;
  }

  li:before {
    vertical-align: top;

    display: inline-block;

    margin: 10px 13px 0px -30px;

    width: 12px;
    height: 12px;

    content: '';

    @include primary_background_color();

    clip-path: url(#triangle-list-clip);
    object-fit: contain;
  }
}

#main .showcase {

  .row {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .items {
    white-space: nowrap;

    .showcase-item:first-of-type {
      margin-left: 0;
    }
    .showcase-item:last-of-type {
      margin-right: 0;
    }
  }

  .spacer {
    display: inline-block;

    @each $breakpoint, $container-max-width in $container-max-widths {

      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        width: calc((100vw - #{$container-max-width - $grid-gutter-width})/2);
      }
    }
  }

  .showcase-item {
    margin: 0 30px;

    width: calc(100vw - 60px);
    min-width: 240px;
    max-width: 310px;

    white-space: normal;
    display: inline-block;

    vertical-align: top;

    text-decoration: none;

    ul {
      padding-left: 40px;

      list-style:none;

      font-size: 18px;

      color: #979797;

      li:before {
        display: inline-block;

        font-family: 'Font Awesome 5 Pro';
        margin:0 16px 0 -40px;

        @include primary_color();

        width: 24px;

        text-align: center;
      }

      li.location:before {
        content: '\f3c5';
        font-weight: 900;
      }

      li.size:before {
        content: '\f424';
        font-weight: 300;
      }

      li.key:before {
        content: '\f084';
        font-weight: 900;
      }

      li.deposit:before {
        content: '\f4d3';
        font-weight: 300;
      }

      li.administration_costs:before {
        content: '\f3d1';
        font-weight: 300;
      }

      li.administration_costs:before {
        content: '\f3d1';
        font-weight: 300;
      }

      li.utilities:before {
        content: '\f0e7';
        font-weight: 300;
      }

    }

    .image {
      width: 100%;
      height: 0;
      padding-top: 75%;
      position: relative;

      .label {
        position: absolute;

        right: 10px;
        top: 10px;
      }

      .sticker {
        position: absolute;

        padding: 0;
        margin: 0;

        width: 90px;

        bottom: 22px;
        right: -5.5px;

        color: white;

        font-size: 22px;
        font-weight: bold;

        line-height: 28px;

        span {
          display: block;

          position: relative;

          padding: 0;
          margin: 0;

          &.details {
            @include primary_color();

            font-size: 14px;
            line-height: 16px;
          }
        }

        &::before {
          background: url("../img/triangle-price.svg");

          position: absolute;

          content: "";

          width: 124px;
          height: 117px;

          left: -30px;
          top: -22px;
        }
      }

      img {
        width: 100%;
        min-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        border-radius: 14px;

        @include primary_background_color(true);

        overflow: hidden;

        object-fit: cover;
      }
    }

    div + h3, div + h4, div + p {
      margin-top: 0.4rem;
    }

    h3 {
      font-size: 1.3em;
    }
    h4 {
      font-size: 1.2em;
    }
    p {
      font-size: 0.8em;

      color: #00000068;
    }
  }
}


.footer-logo {
  display: block;
}

.tagline {
  display: block;

  font-size: 1.2em;

  @include media-breakpoint-down('md', $grid-breakpoints) {
    font-size: 0.8em;
  }
}

footer {
  position: relative;

  min-height: 100px;

  color: #0000009f;

  &::before {
    display: block;
    content: '';
    position: absolute;
    width: 100vw;
    height: calc(100% + 250px);
    background: url("../img/triangle-footer.svg") no-repeat top center;
    background-size: 1440px 874px;
    background-size: cover;
    top: -250px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -2;
  }

  @include media-breakpoint-down('xs', $grid-breakpoints) {
    font-size: 0.6em;
    line-height: 1.3;
  }

  .contact {
    font-size: 1.2em;

    .row {
      margin-left: 0;
      margin-right: 0;

      & > div:nth-child(2) {
        @include media-breakpoint-down('xs', $grid-breakpoints) {
          line-height: 1.3;
        }
      }

      & > div:nth-child(1) {
        position: relative;

        margin-right: $grid-gutter-width;

        @include media-breakpoint-down('xs', $grid-breakpoints) {
          margin-right: $grid-gutter-width / 2;
        }

        padding: 5px 0 0 3px;

        div {
          width: 80px;
          height: 80px;

          @include media-breakpoint-down('xs', $grid-breakpoints) {
            width: 44px;
            height: 44px;
          }

          @include primary_background_color();

          clip-path: url(#contact-li-clip);
          object-fit: contain;
        }

        i {
          position: absolute;
          left: 50%;
          top: 50%;

          color: white;

          transform: translate(-50%, -50%);
        }
      }

    }
  }
}

.page_element_image_text {
  &.left {
    @each $breakpoint, $container-max-width in $container-max-widths {
      @if $breakpoint == lg or $breakpoint == xl {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
          padding-right: calc((100vw - #{$container-max-width}) / 2);
        }
      }
    }
  }
  &.right {
    @each $breakpoint, $container-max-width in $container-max-widths {
      @if $breakpoint == lg or $breakpoint == xl {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
          padding-left: calc((100vw - #{$container-max-width}) / 2);
        }
      }
    }
  }
}

.page_element_image {
  position: relative;

  img {
    width: 100%;
    /*
    max-height: 60vh;

    object-fit: cover;
    */

    overflow: hidden;

    max-height: calc(min(min(60vw, 90vh), 600px));
    object-fit: cover;

  }

  &.full {
    img {
      border-radius: 35px;
    }
  }

  &.left {
    img {
      @include media-breakpoint-up('lg', $grid-breakpoints) {
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
      }
    }
  }

  &.right {
    img {
      @include media-breakpoint-up('lg', $grid-breakpoints) {
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
      }
    }
  }

  .label {
    position: absolute;

    margin: 0;

    top: $grid-gutter-width;
    right: $grid-gutter-width;
  }
}







#menu, #filter {
  display: block;

  position: fixed;

  padding: 10px;

  top: 0px;

  overflow-x: hidden;
  overflow-y: scroll;

  width: 100vw;
  height: 100vh;

  opacity: 0;
  transition: all 0.3s;

  z-index: 100;

  .row {
    margin: auto;
  }

  h1 {
    font-size: 25px;
    line-height: 32px;

    margin-bottom: 30px;
  }
}

#menu {
  background: white;

  left: -100vw;
}



#filter {
  left: 100vw;

  background: #efefef;

  form > div > div {
    background: white;
    border-radius: 13px;

    padding: 12px 16px;
    margin: 16px 0;

    label {
      font-size: 20px;
    }

    & > div {
      line-height: 32px;

      input {
        margin-top: 10px;
        margin-right: 20px;
        float: left;
      }
      label {
        font-size: 18px;
        font-weight: normal;

        display: block;
      }
    }
  }

  form > div > div:last-of-type {
    background: none;
    text-align: center;

    button {
      @include primary_background_color();
      color: white;

      margin: auto;

      max-width: none;
    }
  }
}

#menu-close, #filter-close {
  float: right;

  color: #313130;

  padding: 10px;

  &:hover, &:active {
    border: none;
  }
}

.fa-remove:before, .fa-close:before, .fa-times:before {
  content: "\f00d";
}

#menu-list, #filter-list {
  font-family: 'Ginto Nord', serif;
  font-size: 26px;

  font-weight: 500;

  line-height: 53px;
}

#secondary-menu-list {
  margin-top: 30px;

  &:first-child {
    margin-top: 70px;
  }

  margin-bottom: 20px;

  font-size: 18px;

  font-weight: 300;

  line-height: 24px;

  a {
    color: grey;
    &:hover {
      @include primary_color();
    }
  }
}

body.menu {
  #menu {
    display: block;
    opacity: 100;
    left: 0vw;
  }
}

body.filter {
  #filter {
    display: block;
    opacity: 100;
    left: 0vw;
  }
}

ul.contact-list {
  margin-top: 40px;

  padding-left: 60px;

  list-style:none;

  li {
    margin: 10px 0;
  }

  li:before {
    display: block;

    float: left;

    margin:0 18px 0 -60px;

    @include primary_color();

    width: 42px;

    font-size: 40px;

    line-height: 53px;
    text-align: center;
  }

  li.whatsapp:before {
    content: '🤐';
  }

  li.call:before {
    content: '📞';
  }

  li.mail:before {
    content: '✉️';
  }

  li.instagram:before, li.facebook:before {
    content: ' ';
    display: block;
    width: 42px;
    height: 42px;
    background-size: 42px 42px;
    vertical-align: bottom;
    margin-top: 13px;
  }

  li.instagram:before {
    background-image: url("../img/instagram.png");
  }

  li.facebook:before {
    background-image: url("../img/facebook.png");
  }
}








#main {


  #room-signup-inline {
    display: inline-block;

    width: calc(100% + 80px);

    p {
      padding: 0 10px;

      color: #797979;

      font-size: 14px;
    }

    button, .btn {
      @include primary_background_color();

      color: white;

      &.closed {
        border: 1px solid #E1E1E1;
        background: white;
        color: black;
      }
    }
  }

  .icon-list {
    margin: 32px 0;
  }

  button.whatsapp {
    background: black;

    color: white;
  }

  .room-item ul, ul.icon-list {
    padding-left: 40px;

    list-style:none;

    font-size: 18px;

    color: #979797;

    li:before {
      display: inline-block;

      font-family: 'Font Awesome 5 Pro';
      margin:0 16px 0 -40px;

      @include primary_color();

      width: 24px;

      text-align: center;
    }

    li.location:before {
      content: '\f3c5';
      font-weight: 900;
    }

    li.size:before {
      content: '\f424';
      font-weight: 300;
    }

    li.key:before {
      content: '\f084';
      font-weight: 900;
    }

    li.deposit:before {
      content: '\f4d3';
      font-weight: 300;
    }

    li.administration_costs:before {
      content: '\f3d1';
      font-weight: 300;
    }

    li.administration_costs:before {
      content: '\f3d1';
      font-weight: 300;
    }

    li.utilities:before {
      content: '\f0e7';
      font-weight: 300;
    }

  }

  .room-image {
    position: relative;

    .image-container {
      width: 100%;
      padding-top: 70%; // height

      border-radius: 12px;
      overflow: hidden;

      background: black;

      position: relative;

      z-index: -1;

      img {
        position: absolute;

        min-width: 102%;
        min-height: 102%;

        width: 102%;

        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
      }
    }

    .room-image-label {
      position: absolute;

      top: 12px;
      right: 12px;

      padding: 5px 12px 8px 12px;

      color: white;
      @include primary_background_color();

      font-size: 16px;
      font-weight: 500;

      border-radius: 10px;
    }

    .room-image-sticker {
      position: absolute;

      padding: 0;
      margin: 0;

      width: 90px;

      bottom: 22px;
      right: -5.5px;

      color: white;

      font-size: 22px;
      font-weight: bold;

      line-height: 28px;

      span {
        display: block;

        position: relative;

        padding: 0;
        margin: 0;

        &.details {
          @include primary_color();

          font-size: 14px;
          line-height: 16px;
        }
      }

      &::before {
        background: url("../img/triangle-price.svg");

        position: absolute;

        content: "";

        width: 124px;
        height: 117px;

        left: -30px;
        top: -22px;
      }
    }
  }

  .room-image {
    margin: 20px 0;
  }

  .room-image.more {
    display: none;
  }

  #less-images {
    display: none;
  }

  .show-more {
    #more-images {
      display: none;
    }
    #less-images {
      display: block;
    }
    .room-image.more {
      display: block;
    }
  }
}


#room-signup {
  position: fixed;

  border-top: 1px solid #E9E9E9;

  padding: 5px 16px;

  bottom: 0;

  background: white;

  width: 100vw;
  min-width: 280px;

  .content {
    max-width: 440px!important;
  }

  p {
    color: #797979;

    font-size: 14px;
  }

  a {
    @include primary_background_color();

    width: 100%;

    color: white;

    &.closed {
      border: 1px solid #E1E1E1;
      background: white;
      color: black;
    }
  }

  span {
    color: black;
    padding-left: 30px;
  }

}

.content {
  padding: 0px 32px;
  margin: auto;
  min-height: 0;
}

@media(min-width:1200px) {

  #room-signup {
    display: none;
  }
}



#filter-button {
  float: right;
  width: auto;

  &:focus {
    outline:none;
    box-shadow: none;
  }
}



.signup, .room_signup, .signedup, .room_timeslot_signup {
  #main {
    h2 {
      color: black;
    }

    p {
      color: #797979;
      font-size: 18px;
      line-height: 28px;
    }

    label {
      display: block;

      margin-top: 14px;

      font-family: 'Ginto Nord';
      font-size: 16px;
      line-height: 19px;
    }

    input[type=text], input[type=date], input[type=email], select, textarea {
      display: block;

      border: 1px solid #E1E1E1;

      font-size: 16px;

      width: 100%;
      padding: 5px 0 5px 10px;
    }

    input[type=file], textarea {
      width: 100%;

      border-radius: 13px;
      background-color: #EFEFEF;
    }

    textarea {
      resize: vertical;
    }

    input[type=file] {
      padding: 16px 10px;
    }

    button[type=submit] {
      color: white;
      @include primary_background_color();
    }

    fieldset {
      margin: 32px 0;
    }

    .documents {
      h3 {
        font-family: 'Ginto Nord';
        font-size: 16px;
        line-height: 19px;
      }

      p {
        font-size: 18px;
        line-height: 28px;
      }

      label {
        font-family: 'Ginto';
        font-size: 18px;
        line-height: 28px;
        font-weight: normal;
      }
    }
  }
}

input, button {

}

#main ol {
  counter-reset: listNumbering;
  padding-left: 60px;

  margin-bottom: 44px;

  list-style:none;

  font-size: 22px;
  font-weight: bold;
  line-height: 28px;

  color: #313130;

  li {
    counter-increment: listNumbering;

    margin: 28px 20px 28px 20px;

    font-size: 26px;
    line-height: 28px;

    min-height: 56px;

  }

  li:before {
    box-sizing: border-box;

    display: inline-block;

    font-family: 'Ginto Nord';
    margin: 8px 26px -20px -70px;

    color: white;

    padding-left: 4px;
    width: 44px;
    height: 46px;

    font-size: 20px;
    line-height: 44px;

    text-align: center;

    content: counter(listNumbering);


    clip-path: url(#triangle-ol-clip);
    object-fit: contain;
    background-size: 44px 46px;

    @include primary_background_color();
  }

  li.done {
    color: #919191;
    background-color: transparent;
  }

  li.done:before {
    content: '';
    clip-path: none;
    background-color: transparent!important;
    background-image: url('../img/triangle-ol-done.svg');
  }
}

#main .amenities {
  list-style:none;
  line-height: 54px;
  padding-left: 26px;

  li:before {
    box-sizing: border-box;

    display: inline-block;

    width: 11px;
    height: 20px;
    transform: rotate(45deg);
    border-bottom: 3px solid lightgray;
    border-right: 3px solid lightgray;
    @include primary_border_color();

    content: '';

    margin-left: -20px;
    margin-right: 26px;
  }
}

#main .criteria {
  list-style:none;
  line-height: 54px;
  padding-left: 26px;

  li:before {
    box-sizing: border-box;

    display: inline-block;

    width: 10px;
    height: 10px;

    border-radius: 5px;
    @include primary_background_color();

    content: '';

    margin-left: -20px;
    margin-right: 26px;
  }
}

#main #form_duration {
  label {
    display: inline-block;
    font-weight: normal;
    width: calc(100% - 40px);
  }

  input {
    margin-right: 20px;
    width: 20px;
  }
}